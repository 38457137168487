@import '../_variables.scss';

html {
  .mat-form-field {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          padding: 0.4375em 0 !important;
          .mat-form-field-label-wrapper {
            .mat-form-field-label {
              font-family: $main-ff !important;
              font-size: 13px;
              font-weight: 500;
              letter-spacing: 0.01em;
              top: 1.28125em !important;
            }
          }
          .mat-select-arrow-wrapper {
            .mat-select-value {
              font-family: $main-ff !important;
              font-weight: normal !important;
            }
            .mat-select-arrow {
              border-width: 4px;
              color: #aeaeae;
            }
          }
        }
      }

      .mat-form-field-underline {
        background-color: #d3d8dd;
        bottom: 1.25em !important;
      }
      .mat-input-element {
        font-family: $main-ff !important;
        font-weight: normal;
        //padding-top: $module-rem / 2;
        //padding-bottom: $module-rem * .2;
      }
    }
    &.no-padding {
      height: 100%;
      overflow: hidden;
      .mat-form-field-wrapper {
        align-items: center;
        display: flex;
        padding: 0;
        height: 100%;
        overflow: hidden;

        .mat-form-field-flex {
          .mat-form-field-infix {
            padding: 0;
            border: none;
            height: 100%;
            overflow: hidden;

            .mat-input-element {
              overflow: hidden;
              line-height: 1.6;
            }
          }
        }
      }
    }
  }
  &.mat-focused {
    .mat-datepicker-toggle-default-icon {
      fill: $accent-blue !important;
    }
    .mat-form-field-wrapper {
      .mat-form-field-label {
        color: $accent-blue !important;
      }
      .mat-form-field-flex {
        .mat-form-field-infix {
          .mat-select-arrow-wrapper {
            .mat-select-arrow {
              color: $accent-blue;
            }
          }
        }
      }
    }

    .mat-form-field-ripple {
      background-color: $accent-blue !important;
    }
  }
  &.mat-form-field-invalid {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          .mat-select-arrow-wrapper {
            .mat-select-arrow {
              color: $red;
            }
          }
        }
      }
    }
  }
}
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: $accent-blue;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
  background-color: $red;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: $accent-blue;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: $red;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.34375rem) scale(0.85) !important;
  color: #818e9b !important;
}

.mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
  height: 15px;
  width: 15px;
}
