@import '../variables';

$_unchecked-color: #d5d5d5;
$_checked-color: $accent-blue;

app-root {
  .mat-checkbox {
    label {
      margin: 0 !important;
    }
    .mat-checkbox-inner-container {
      .mat-checkbox-frame {
        border-color: $_unchecked-color;
      }
    }
    .mat-checkbox-ripple {
      display: none;
    }
  }
  .mat-checkbox-checked {
    .mat-checkbox-inner-container {
      .mat-checkbox-background {
        background-color: $_checked-color !important;
      }
    }
  }
}
