@import '../variables';

$_h-padding: $module-rem * 2.4;
$_v-padding: $module-rem * 1.6;

app-root {
  .mat-card {
    color: $main-color;
    padding: ($_v-padding) ($_h-padding);
    font-family: $main-ff !important;
    margin-bottom: $module-rem * 3;

    &.padding-sm {
      padding: $_v-padding;
    }
    &.padding-none {
      padding: 0;
    }
    &:not([class*='mat-elevation-z']) {
      box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
    }
    .mat-card-header {
      align-items: baseline;
      color: $black;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      margin-bottom: $_v-padding;

      .mat-card-title {
        font-size: 15px;
        line-height: 22px;
      }
      .mat-card-header-text {
        margin: 0;
        font-size: 15px;
        color: $black;
      }
      &:after {
        content: '';
        position: absolute;
        display: block;
        left: -$_h-padding;
        right: -$_h-padding;
        bottom: 0;
        border-top: 1px solid $divider;
      }
    }
  }
}
