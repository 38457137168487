.count-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  justify-content: space-around;
  .count-item {
    width: 100%;
    font-weight: bold;
    font-size: 3em;
    text-align: center;
    &:nth-child(2) {
      padding: 0 20px;
    }
  }
}

.downloads-container {
  padding-bottom: 2%;
  .downloads-metric {
    h6 {
      margin-bottom: 0;
      text-align: left;
    }
  }
}

.chart-data-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  .chart-item {
    width: 100%;
    padding: 50px 0;
    &:nth-child(1) {
      padding-right: 20px;
    }
  }
}
