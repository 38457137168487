@import '../../../../assets/sass/variables';

.account-info {
  display: block;

  .account-info-wrap {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    .img-wrap {
      align-items: center;
      border-radius: 50%;
      display: flex;
      height: 46px;
      justify-content: center;
      min-width: 46px;
      margin-right: ($module-rem * 1.5);
      overflow: hidden;
      width: 46px;
      &.active {
        border: 2px solid $accent-blue;
      }
      > .account-img {
        height: 100%;
        width: auto;
      }
    }
    .info {
      display: flex;
      flex-direction: column;

      .name {
        color: $black;
        font-size: 14px;
        line-height: 21px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .email {
        line-height: 17px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 160px;
      }
    }
  }
  &.minimized {
    .account-info-wrap {
      .img-wrap {
        margin: 0;
      }
    }
  }
  &.mobile {
    .account-info-wrap {
      .img-wrap {
        height: 24px;
        width: 24px;
        min-width: 24px;
        margin: 0;
      }
    }
  }
}
