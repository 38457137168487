@import './variables';

html {
  font-size: $main-fs;
}
body {
  background: $main-bg;
  background: var(--main-bg);
  color: $main-color;
  color: var(--main-color);
  font-family: $main-ff;
  font-size: 1rem;
  font-weight: $main-fw;
  letter-spacing: 0.01em;
  line-height: $main-lh;
  margin: 0 !important;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input,
optgroup,
select,
textarea {
  font: inherit;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: $black;
  font-family: $headers-ff;
  font-weight: $headers-fw;
  line-height: $main-lh;
  letter-spacing: 0.01em;
  margin-top: $module-rem * 2;
  margin-bottom: $module-rem * 2;
}
h1,
.h1 {
  font-size: 4rem;
  line-height: 1.07142857;
  margin-bottom: 3.1428572rem;
}
h2,
.h2 {
  font-size: 2.85714286rem;
  line-height: 1;
}
h3,
.h3 {
  font-size: 2rem;
  line-height: 1.42857143;
}
h4,
.h4 {
  font-size: 1.71428572rem;
  line-height: 1.25;
}
h5,
.h5 {
  font-size: 1.42857143rem;
  line-height: 1.5;
}
h6,
.h6 {
  font-size: 1rem;
}
p,
ul,
ol,
pre,
table,
blockquote,
.blockquote {
  margin-top: 0;
  margin-bottom: $module-rem * 2;
  letter-spacing: 0.01em;

  &:last-child {
    margin-bottom: 0;
  }
}
ul ul,
ol ol,
ul ol,
ol ul {
  margin-top: 0;
  margin-bottom: 0;
}
hr,
.hr {
  border-top: 1px solid $divider;
  margin: 0;
}
sub,
sup {
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
a {
  color: $link-color;
  text-decoration: none;
  transition: color 0.2s $animation;

  &:hover,
  &:focus {
    color: darken(#2fa7ff, 20);
    outline: none;
    text-decoration: none;
  }
}
img {
  height: auto;
  max-width: 100%;
}
button {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}
textarea {
  box-shadow: none;
}
// mark,
// .mark {
//   padding: 0 0.2em;
//   background-color: $accent-blue;
//   color: $white;
// }
// ::selection {
//   background-color: $accent-blue;
//   color: $white;
// }
// ::-moz-selection {
//   background-color: $accent-blue;
//   color: $white;
// }

// //Scroll
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 2px rgba(#000, 0.1);
// }
// ::-webkit-scrollbar {
//   background: rgba(#fff, 0.2);
//   position: absolute;
//   width: 2px;
// }
// ::-webkit-scrollbar:hover {
//   display: block;
// }
// ::-webkit-scrollbar:horizontal {
//   height: 2px;
// }
