@import './mixins';

$main-palette: (
  50: #e2f3fd,
  100: #b7e1fb,
  200: #88cef8,
  300: #58baf5,
  400: #34abf3,
  500: #109cf1,
  600: #0e94ef,
  700: #0c8aed,
  800: #0980eb,
  900: #056ee7,
  A100: #ffffff,
  A200: #dbeaff,
  A400: #a8ccff,
  A700: #8fbdff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

//Colors
$accent-blue: #109cf1;
$purple: #885af8;
$yellow: #ffb946;
$green: #2ed47a;
$red: #f7685b;

$black: #192a3e;
$table-black: #323c47;
$table-gray: #707683;

$divider: #ebeff2;

$dark-blue-text: #334d63;
$gray: #90a0b7;
$icon-gray: #c2cfe0;

$white: #ffffff;

//Main
$main-fs: 13px;
$main-ff: 'Poppins', sans-serif;

$main-fw: 400;
$medium-fw: 500;
$semibold-fw: 600;

$module: 10px;
$main-lh: ($module * 2 / $main-fs);
$module-rem: ($module / $main-fs) * 1rem;

$link-color: #109cf1;
$main-bg: #e5e5e5;
$main-color: $table-black;
$boxed-width: 1140px;

$top-bar-height: 60px;

$side-nav-width: 256px;
$side-nav-width-minimized: 68px;

:root {
  --main-color: #90a0b7;
  --main-bg: #e5e5e5;
  --boxed-width: 1140px;
}

$shape: 4px;
$shadow: 6px 6px 20px 0 rgba(0, 0, 0, 0.08);
$shadow-hover: 3px 3px 10px 0 rgba(0, 0, 0, 0.15);

$animation: ease-in-out;

//Headers
$headers-ff: $main-ff;
$headers-fw: $medium-fw;

//Media
$max543: only screen and
  (
    max-width: 543px,
  );
$min544: only screen and
  (
    min-width: 544px,
  );
$max767: only screen and
  (
    max-width: 767px,
  );
$min768: only screen and
  (
    min-width: 768px,
  );
$max991: only screen and
  (
    max-width: 991px,
  );
$min992: only screen and
  (
    min-width: 992px,
  );
$max1199: only screen and
  (
    max-width: 1199px,
  );
$min1200: only screen and
  (
    min-width: 1200px,
  );
