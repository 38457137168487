@import '../../../../assets/sass/variables';

$_bg: $white;
$_box-shadow: 6px 0px 18px rgba(#000, 0.06);

.side-bar {
  background: $_bg;
  box-shadow: $_box-shadow;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  width: $side-nav-width;

  .side-nav-wrap {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ($module-rem * 2.4) 0 ($module-rem * 2);
    .side-bar-toggle {
      padding: 0 $module-rem * 2.7;
    }

    .account-info {
      padding: 0 $module-rem * 2.4;
      transition: padding 0.3s ease-in-out;
      margin: 0 0 ($module-rem * 3);
    }
  }
  .divider {
    margin: 0 (-$module-rem * 2);
    border-color: $divider;
  }
  &.minimized {
    width: $side-nav-width-minimized;

    .side-nav-wrap {
      .account-info {
        padding: 0 ($module-rem * 1.1);
      }
    }
  }
  &.mobile {
    position: absolute;
    top: unset !important;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 44px;
    flex-direction: row;

    .side-nav-wrap {
      padding: 0 $module-rem * 1.6;
    }

    .account-info,
    .side-bar-toggle,
    .logo {
      display: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  .side-bar {
    position: absolute;
    top: unset !important;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 44px;
    flex-direction: row;

    .side-nav-wrap {
      padding: 0 $module-rem * 1.6;
    }

    .account-info,
    .side-bar-toggle,
    .logo,
    .logo-host {
      display: none;
    }
  }
}

@media only screen and (min-width: 600px) {
  .side-bar {
    width: $side-nav-width-minimized;

    .side-nav-wrap {
      .account-info {
        padding: 0 ($module-rem * 1.1);
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .side-bar {
    width: $side-nav-width;
  }
}
