@import '../_variables.scss';

$_shape: $shape;
$_shadow: 0px 6px 18px rgba(#000, 0.06);
$_row-shadow-hover: 0px 2px 8px rgba(#000, 0.1);

html {
  .mat-table {
    border-radius: $_shape;
    box-shadow: $_shadow;
    color: $table-gray;
    font-family: $main-ff;
    overflow: hidden;
    width: 100%;
    max-width: 100%;

    .counter {
      align-items: center;
      background-color: $accent-blue;
      border-radius: $_shape;
      cursor: pointer;
      font-size: 9px;
      color: #fff;
      justify-content: center;
      display: flex;
      height: 16px;
      width: 16px;
    }
    .mat-header-row {
      .mat-header-cell {
        color: rgba($dark-blue-text, 0.5);
        font-weight: 500;
        border-bottom-color: $divider;

        &.actions-th {
          padding-right: 0;
        }
      }
      .selected {
        color: $black;
        font-weight: 500;
      }
      .actions {
        align-items: center;
        display: flex;
        margin: 0 (-$module-rem * 0.4);

        .mat-icon {
          cursor: pointer;
          color: $icon-gray;
          font-size: 18px;
          height: 15px;
          width: 15px;
          margin: 0 ($module-rem * 0.4);
          transition: color 0.2s ease-in-out;

          &:hover {
            color: $table-gray;
          }
          &:first-child {
            margin-left: $module-rem * 1.4;
          }
        }
        .tc-circle-badge {
          cursor: pointer;
          margin: 0 ($module-rem * 0.4);
        }
      }
    }
    .mat-row {
      cursor: pointer;
      background: none;
      transition: box-shadow 0.2s ease-in-out;

      &.selected {
        background-color: #F2F9FF;
      }
      &:hover {
        z-index: 10;
        box-shadow: $_row-shadow-hover;
      }

      .mat-cell {
        border-bottom-color: $divider;
        color: $table-gray;
        font-weight: 300;
        padding-bottom: $module-rem * 2;
        padding-top: $module-rem * 2;
        padding-right: $module-rem * 2.4;
      }
    }
  }
}
