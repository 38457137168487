@import '../_variables.scss';

html {
  .mat-select {
    .mat-select-value {
      color: currentColor !important;

      .mat-select-value-text {
        font-family: $main-ff !important;
        font-weight: normal;
      }
    }
  }
}
