@import '../../../../assets/sass/variables';

.account-container {
  .card {
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  }
  .card-content {
    align-items: flex-end;
    display: flex;
    flex-direction: column;

    .selects {
      margin: ($module-rem * 1.2) 0 ($module-rem * 3.8);
      width: 100%;

      .mat-form-field {
        color: $table-black !important;
        font-family: $main-ff;
        font-weight: 500;
        margin-bottom: $module-rem * 1.3;
        width: 100%;

        .mat-select {
          padding-top: $module-rem / 2;
          padding-bottom: $module-rem * 0.3;
        }
      }
    }
  }
  .title {
    color: $black;
    font-size: 18px;
    margin: ($module-rem * 1.6) 0 ($module-rem * 2.4);
    line-height: 27px;
  }
  .tc-btn {
    margin-left: auto;
    min-width: 160px;

    @media only screen and (max-width: 768px) {
      display: block;
      width: 100%;
    }
  }
}
