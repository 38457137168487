@import "../../../../assets/sass/variables";

$_bg: $white;
$_height: $top-bar-height;

.top-nav-bar {
  background: $_bg;
  box-shadow: 0 1px 0 $divider;
  border-bottom: 1px solid $divider;
  display: block;
  //height: $_height;
  position: absolute;
  right: 0;
  top: 0;
  transition: width 0.3s ease-in-out;
  //width: calc(100% - #{$side-nav-width});

  .navbar-wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 100%;
    //padding: 0 $module-rem * 2.6;

    > * {
      margin: 0 $module-rem;
    }
  }
  &.minimized {
    width: calc(100% - #{$side-nav-width-minimized});
  }
  &.mobile {
    width: 100%;
    height: 44px;

    .navbar-wrap {
      padding: 0 $module-rem * 0.6;
    }
    .app-notifications {
      margin-left: auto;
    }
    .page-header {
      color: $black;
      font-size: 15px;
      font-weight: 500;
      flex: 1;
      line-height: 22px;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 600px) {
  .top-nav-bar {
    width: 100%;
    height: 44px;

    .navbar-wrap {
      padding: 0 $module-rem * 0.6;
    }
    .app-notifications {
      margin-left: auto;
    }
    .page-header {
      color: $black;
      font-size: 15px;
      font-weight: 500;
      flex: 1;
      line-height: 22px;
      text-align: center;
    }
  }
}
@media only screen and (min-width: 600px) {
  .top-nav-bar {
    width: calc(100% - #{$side-nav-width-minimized});
    .page-header {
      display: none;
    }
  }
}

@media only screen and (min-width: 768px) {
  .top-nav-bar {
    height: $_height;
    width: calc(100% - #{$side-nav-width});
    .navbar-wrap {
      padding: 0 $module-rem * 2.6;
    }
    .page-header {
      display: none;
    }
  }
}
