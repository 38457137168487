@import '../../../../assets/sass/variables';

.logo-host {
  display: block;
  height: 60px;
  width: 100%;
  transition: width 0.3s ease-in-out;

  .logo-wrap {
    align-items: center;
    height: 100%;
    display: flex;
    padding: 0 ($module-rem * 2.4);
    justify-content: flex-start;
  }
  .logo-img {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
  &.minimized {
    .logo-wrap {
      justify-content: center;
      padding: 0;
    }
  }
}

@media only screen and (min-width: 600px) {
  .logo-wrap {
    justify-content: center;
    padding: 0;
  }
}
