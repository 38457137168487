@import '../../../../assets/sass/variables';

$_module-rem: $module-rem;
$_border-width: 1px;
$_border-style: solid;
$_fs: 1rem;
$_ff: $main-ff;
$_fw: bold;
$_lh: 19px;
$_tt: none;
$_padding: ($_module-rem * 1.1) ($_module-rem * 2.5);
$_shape: $shape;
$_transition: $animation;
$_shadow: none;
$_shadow-hover: none;

$_shadow: 0 4px 10px;
$_shadow-hover: 0 8px 16px;
$_shadow-pressed: 0 2px 6px;

//btn colors
$_types: (
  default: (
    bg: $accent-blue,
    color: #fff,
    hover-bg: #34aff9,
    pressed-bg: #098edf,
  ),
  error: (
    bg: $red,
    color: #fff,
    hover-bg: #f77367,
    pressed-bg: #ff3c5f,
  ),
);
.mt-btn {
  align-items: center;
  border: transparent 0 $_border-style;
  border-radius: $_shape;
  cursor: pointer;
  display: inline-flex;
  font-family: $_ff;
  font-size: $_fs;
  font-weight: $_fw;
  justify-content: center;
  line-height: $_lh;
  outline: none;
  padding: $_padding;
  position: relative;
  text-align: center;
  text-transform: $_tt;
  touch-action: manipulation;
  transition: background 0.2s $_transition, border 0.2s $_transition,
    box-shadow 0.2s $_transition, color 0.2s $_transition,
    opacity 0.2s $_transition;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  will-change: background, border, box-shadow, color, opacity;
  -webkit-appearance: none;
  box-shadow: 0 4px 10px rgba(52, 173, 249, 0.034);
  background-clip: padding-box;

  .btn-default {
    background-color: $accent-blue;
    color: #fff;
    &:hover {
      background-color: #34aff9;
    }
  }

  .btn-error {
    background-color: $red;
    color: #fff;
    &:hover {
      background-color: #f77367;
    }
  }
  // .btn-error-outline {
  //   border: 1px solid $red !important;
  //   background-color: transparent !important;
  // }

  .btn-text {
    align-items: center;
    display: flex;
    line-height: $_lh;
    font-weight: 600;
    justify-content: center;
    font-size: $_fs;
    letter-spacing: 0.01em;

    &:empty {
      margin: 0;
    }
  }
  .btn-icon {
    font-size: 1.3em;
    margin: 0 ($_module-rem / 2);
  }

  //colors - (default, error)
  @each $_type, $_color in $_types {
    &.btn-#{$_type} {
      background-color: map-get($_color, bg);
      background-clip: padding-box;
      color: map-get($_color, color);
      box-shadow: $_shadow rgba(map-get($_color, hover-bg), 0.24);

      &:hover {
        background-color: map-get($_color, hover-bg);
        box-shadow: $_shadow-hover rgba(map-get($_color, hover-bg), 0.2);
      }
      &:active {
        background-color: map-get($_color, pressed-bg);
        box-shadow: $_shadow-pressed rgba(map-get($_color, pressed-bg), 0.3);
      }
      &.btn-secondary {
        background: transparent;
        box-shadow: none;
        color: map-get($_color, bg);
        border: 1px solid map-get($_color, bg);
        &:hover {
          box-shadow: none;
          color: map-get($_color, hover-bg);
          border: 1px solid map-get($_color, hover-bg);
        }
        &:active {
          box-shadow: none;
          color: map-get($_color, pressed-bg);
          border: 1px solid map-get($_color, pressed-bg);
        }
      }
      &.btn-tertiary {
        background: none;
        border: none;
        box-shadow: none;
        color: map-get($_color, bg);

        &:hover {
          color: map-get($_color, hover-bg);
        }
        &:active {
          color: map-get($_color, pressed-bg);
        }
      }
    }
  }
  &.custom-bg-color,
  &.custom-color {
    border-color: transparent;
  }
  &.custom-border-color,
  &.custom-bg-color {
    color: #fff;
  }

  &.btn-outline {
    background-color: transparent;
  }
  &.btn-disabled {
    cursor: not-allowed;
    opacity: 0.8;
    pointer-events: none;
    box-shadow: none;
    background: $icon-gray;

    &.btn-secondary {
      border: 1px solid $icon-gray;
      color: $icon-gray;
    }
    &.btn-tertiary {
      color: $accent-blue;
      opacity: 0.4;
    }
  }
  &.block {
    display: block;
    width: 100%;
  }
}
