@import '../_variables';

app-root {
  .mat-tab-group {
    font-family: $main-ff !important;
    .mat-tab-header {
      .mat-tab-header-pagination {
        display: none !important;
      }
      .mat-ink-bar {
        background: $accent-blue !important;
      }
      .mat-tab-labels {
        .mat-tab-label {
          font-family: $main-ff !important;
          color: $dark-blue-text;
          min-width: 100px;
          padding: 0 $module-rem * 3.2;

          &.mat-tab-label-active {
            .title {
              color: $accent-blue;
            }
            .mat-icon {
              color: $accent-blue;
            }
          }
        }
      }
    }
    &.contacts-tabs {
      font-family: $main-ff !important;
      .mat-tab-header {
        border: none !important;
        .mat-tab-header-pagination {
          display: none !important;
        }
        .mat-ink-bar {
          background: $dark-blue-text !important;
        }
        .mat-tab-labels {
          border-bottom: 1px solid $divider !important;

          .mat-tab-label {
            color: $dark-blue-text;
            min-width: 100px;
            width: unset;
            padding: 0 $module-rem * 3.2;

            .mat-icon {
              font-size: 16px;
              height: 18px;
              margin-right: $module-rem * .8;
              transition: color .2s ease-in-out;
              width: 16px;
            }
            &.mat-tab-label-active {
              .mat-icon {
                color: $dark-blue-text;
              }
            }
          }
        }
      }
    }
    &.settings-tabs {
      padding: 0 !important;
        .mat-tab-labels {
          .mat-tab-label {
            min-width: 100px;
            width: 200px;
            height: unset !important;
          }
        }
      }
  }
}

.mat-tab-label.mat-tab-label-active:not(.mat-tab-disabled),
.mat-tab-label.mat-tab-label-active.cdk-keyboard-focused:not(.mat-tab-disabled) {
  color: $accent-blue;
  opacity: 1;
}
