@import './variables';

.regular {
  font-weight: $main-fw;
  font-size: 13px;

  &-12pt {
    font-size: 12px;
  }
  &-10pt {
    font-size: 10px;
  }
}

.medium {
  font-weight: $medium-fw;

  &-20pt {
    font-size: 20px;
  }
  &-18pt {
    font-size: 18px;
  }
  &-15pt {
    font-size: 15px;
  }
  &-14pt {
    font-size: 14px;
  }
  &-13pt {
    font-size: 13px;
  }
  &-12pt {
    font-size: 12px;
  }
  &-11pt {
    font-size: 11px;
  }
}

.semibold {
  font-weight: $semibold-fw;
  font-size: 13px;
}

.mat-divider {
  border-color: $divider !important;
}

.cdk-overlay-connected-position-bounding-box {
  right: 38px !important;
  @media only screen and (max-width: 768px) {
    right: 18px !important;
  }
}
.cdk-overlay-pane {
  @media only screen and (max-width: 330px) {
    right: 18px !important;
  }
}

.elem-list {
  padding: $module-rem;
  margin: -$module-rem;

  > * {
    margin: $module-rem;
  }
}

.mat-progress-bar-fill::after {
  background-color: $green;
  border-radius: 3px;
}

mat-progress-bar .mat-progress-bar-buffer {
  border-radius: 3px;
}

.select {
  .mat-form-field-wrapper {
    padding-bottom: 0;
    text-align: right;
  }
  .mat-form-field-infix {
    width: unset;
    min-width: 30px;
    border: none;

    .mat-select-value {
      color: $accent-blue !important;
      display: inline !important;
    }
    .mat-select-arrow-wrapper {
      .mat-select-arrow {
        border-width: 2px;
        color: #aeaeae;
      }
    }
  }
}
.mat-option {
  &.mat-selected {
    color: $accent-blue !important;
  }
}
.chart-container {
  height: 200px;
  position: relative;
  overflow: hidden;
  width: 100%;

  &.container-h-100 {
    height: 100px;
  }
  &.container-h-180 {
    height: 180px;
  }
  &.container-h-50 {
    height: 50px;
  }
  &.container-h-240 {
    height: 240px;
  }
  &.container-h-250 {
    height: 250px;
  }
  &.container-h-300 {
    height: 300px;
  }
  &.container-h-320 {
    height: 320px;
  }
  &.container-h-350 {
    height: 350px;
  }
  &.container-h-360 {
    height: 360px;
  }
  &.container-h-380 {
    height: 380px;
  }
  &.container-h-385 {
    height: 385px;
  }
  &.container-h-400 {
    height: 400px;
  }
  &.container-w-180 {
    width: 180px;
  }
  &.container-w-235 {
    width: 235px;
  }
}
.mobile-first {
  @media only screen and (max-width: 992px) {
    order: -1;
  }
}
.mat-icon {
  color: $icon-gray;
}

.user-option {
  align-items: center;
  display: flex;

  img {
    border-radius: 50%;
    margin-right: $module-rem;
    min-height: 24px;
    min-width: 24px;
  }
}

.full-width-modal {
  margin: $module-rem * 1.6;
  max-width: 435px;
  width: 100%;
}

.color-accent {
  color: $accent-blue !important;
}

.svg-icon {
  svg {
    path {
      fill: currentColor !important;
    }
  }
}

.activity-list {
  .list-subheader {
    color: rgba($dark-blue-text, 0.5);
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    margin: 0 0 ($module-rem * 3.2);

    @media only screen and (max-width: 768px) {
      margin: 0 0 ($module-rem * 2.6);
    }

    &:not(:first-child) {
      margin: $module-rem * 5.5 0 ($module-rem * 4);

      @media only screen and (max-width: 768px) {
        margin: $module-rem * 4.8 0 ($module-rem * 2.4);
      }
    }
  }
  .list-item {
    padding: 0 0 0 ($module-rem * 1.6) !important;
    height: 48px !important;

    &:not(:last-child) {
      margin-bottom: $module-rem * 3.2;

      @media only screen and (max-width: 768px) {
        margin-bottom: $module-rem * 3.8;
      }
    }

    @media only screen and (max-width: 768px) {
      height: 60px !important;
      padding: 0 !important;
    }
    .item-icon {
      align-items: center;
      display: flex;
      background: $icon-gray;
      border-radius: 50%;
      justify-content: center;
      color: #fff;
      font-size: 15px !important;
      height: 12px !important;
      width: 12px !important;
      padding: $module-rem !important;
      margin-right: $module-rem * 2.4;
    }
    .item-text {
      display: flex;
      flex-direction: column;
      font-family: $main-ff !important;
      height: 100%;

      .item-title {
        color: $table-black;
        font-size: 15px;
        font-weight: 500;
        line-height: 25px;
        margin-bottom: $module-rem / 2;

        @media only screen and (max-width: 768px) {
          line-height: 15px;
        }
      }
      .item-date {
        color: $table-gray;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        margin-top: auto;

        @media only screen and (max-width: 768px) {
          line-height: 12px;
        }
      }
    }
  }
}

.delete-btn {
  cursor: pointer;
  font-size: 18px;
  height: 16px;
  transition: color 0.2s ease-in-out;
  width: 16px;

  &:hover {
    color: $red;
  }
}

.settings-tab {
  align-items: flex-start;
  border-bottom: 1px solid $divider;
  border-right: 2px solid transparent;
  cursor: pointer;
  display: flex;
  padding: ($module-rem * 1.6) 0;
  min-height: 68px;
  transition: border-right-color 0.2s ease-in-out;

  &.active {
    border-right-color: $accent-blue;

    .icon {
      .mat-icon {
        color: $accent-blue;
        transition: color 0.2s ease-in-out;
      }
    }
  }
  .icon {
    margin-right: $module-rem;

    .mat-icon {
      color: $icon-gray;
    }
  }
  .text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;

    .title {
      color: $black;
      font-size: 13px;
      font-weight: 500;
      line-height: 19px;
      margin: 0;
    }
    .desc {
      color: $gray;
      font-size: 10px;
      line-height: 15px;
    }
  }
}

.mat-form-field {
  &.no-border {
    .mat-form-field-infix {
      border: none;
    }
  }
}

.mat-icon-button {
  &.small {
    height: 24px;
    width: 24px;

    .mat-button-wrapper {
      align-items: center;
      height: 24px;
      display: flex;

      .mat-icon {
        font-size: 28px;
        height: 24px;
        width: 24px;
      }
    }
  }
}
