@import '../../../assets/sass/variables';

.email-confirmation-page {
  .icon {
    color: $green;
    font-size: 20vh;
    display: block;
    margin: 0 auto 35px auto;

    &.error {
      color: $red;
    }
  }
  .login-link {
    color: $accent-blue;
    text-align: center;
    margin-top: 25px;
    display: block;
  }
}
