@import '../../../../assets/sass/variables';

.menu-host {
  
  .menu-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .menu-li {
      height: 40px;
      display: block;
      margin: 0;
      padding: 0 ($module-rem * 0.2);
    }
    .item-link {
      align-items: center;
      color: $table-black;
      display: flex;
      font-weight: 500;
      justify-content: flex-start;
      height: 100%;
      letter-spacing: 0.01em;
      transition: color 0.2s ease-in-out;
      white-space: nowrap;

      &.active,
      &:hover {
        color: $link-color;

        .item-icon {
          color: $link-color;
        }
      }
      .item-icon {
        color: $icon-gray;
        margin-right: ($module-rem * 1.8);
        font-size: 19px;
        display: block;
        transition: color 0.2s ease-in-out;
        height: 19px;
        width: 15px;
      }
    }
  }
  .filters {
    overflow: hidden;
    padding: 0 $module-rem 0 ($module-rem * 3.25);
  }
  .divider-item {
    padding: 0;
    margin: $module-rem * 1.4 0;

    .divider {
      margin: 0 (-$module-rem * 2.4);
    }
  }
  &.minimized {
    .item-text {
      visibility: hidden;
    }
  }
  &.mobile {
    padding: 0;

    .menu-ul {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item-link {
        .item-icon {
          font-size: 25px;
          width: 25px;
          margin: 0;
        }
      }
      .filters,
      .divider-item {
        display: none;
      }
      .menu-li {
        padding: 0;

        .item-text {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .menu-host {
    padding: 0;

    .menu-ul {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item-link {
        .item-icon {
          font-size: 25px;
          width: 25px;
          margin: 0;
        }
      }
      .filters,
      .divider-item {
        display: none;
      }
      .menu-li {
        padding: 0;

        .item-text {
          display: none;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .menu-host {
    display: block;
    height: 100%;
    padding: 0 ($module-rem * 2.2) ($module-rem * 2);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .item-text {
    visibility: hidden;
  }
}

@media only screen and (min-width: 768px) {
  .item-text {
    visibility: visible;
  }
}
