@import '../../../assets/sass/variables';
.password-reset-page {
  .tc-btn {
    display: block;
    width: 100%;
    padding: 15px !important;
    margin-top: 35px !important;
  }
  .login-link {
    color: $red;
    text-align: center;
    margin-top: 25px;
    display: block;
  }
}
