@import '../../../../assets/sass/variables';

$_shape: $shape;
$_shadow: 0 2px 10px rgba(#000, 0.08);
$_shadow-dragged: 0 0 16px rgba(#000, 0.14);

$_padding: ($module-rem * 1.6) ($module-rem * 2.4) ($module-rem * 0.1)
  ($module-rem * 2.4);
$_padding-sm: ($module-rem * 1.6);
$_dark-grey-text: #4c5862;

.counting-card {
  width: 100%;
  background: #ffffff;
  border-radius: $_shape;
  box-shadow: $_shadow;
  border-left: 2px solid;
  display: block;
  padding: $_padding;
  position: relative;
  //cursor: pointer;
  margin: ($module-rem * 1.6) 0 !important;
  transition: box-shadow 0.2s ease-in-out;

  @media only screen and (max-width: 992px) {
  }
  @media only screen and (max-width: 768px) {
    padding: $_padding-sm;
  }
  &:hover {
    box-shadow: $_shadow-dragged;
  }
  &.low {
    border-left-color: $yellow;
  }
  &.high {
    border-left-color: $green;
  }
  &.urgent {
    border-left-color: $red;
  }
  &.dragged {
    box-shadow: $_shadow-dragged;
  }
  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: $module-rem * 0.8;

    .title {
      color: $_dark-grey-text;
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      line-height: 21px;
    }
  }

  .number-block {
    margin-bottom: $module-rem * 2.2;
    text-align: center;
  }
}
