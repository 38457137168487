@import '../../../assets/sass/variables';

$_shape: $shape;
$_profile-gray: #4c5862;

$_facebook-color: #3b5998;
$_twitter-color: #00acee;

.list-page-header {
  display: flex;
  margin-bottom: 35px;
  .filter {
    label {
      margin-right: 5px;
      color: black;
      font-size: 14px;
    }
  }
  .create-btn {
    margin-left: auto;
  }
}

.image-chain-container {
  width: 100%;
  margin-bottom: $module-rem * 2.4;

  .img-wrap {
    align-items: center;

    display: flex;
    justify-content: center;
    border-radius: 10px;
    height: 100%;

    position: relative;

    .no-picture {
      position: absolute;

      .no-picture-icon {
        max-width: 18px;
      }
    }
    .input {
      display: none;
    }
    .edit-btn {
      cursor: pointer;
      align-items: center;
      background: $accent-blue;
      border: none;
      border-radius: 50%;
      bottom: 10px;
      box-shadow: 0 4px 4px rgba(#000, 0.08);
      display: flex;
      justify-content: center;
      height: 50px;
      outline: none;
      position: absolute;
      right: 15px;
      transition: box-shadow 2s ease-in-out;
      width: 50px;
      color: white;

      &.active {
        .mat-icon {
          color: $accent-blue;
        }
      }
      &:hover {
        box-shadow: 0 2px 2px rgba(#000, 0.08);

        .mat-icon {
          transition: color 0.2s ease-in-out;
          color: $accent-blue;
        }
      }
      .mat-icon {
        color: $dark-blue-text;
        font-size: 14px;
        height: 14px;
        width: 14px;
      }
    }
    > img {
      width: auto;
      height: 300px;
      border-radius: 10px;
    }
  }
}
