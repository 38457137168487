@import '../../../../assets/sass/variables';

$_shape: $shape;
$hover-bg: #F5F8FA;

.search {
  width: 100%;

  .search-input-wrap {
    align-items: center;
    background-color: transparent;
    border-radius: $_shape;
    cursor: text;
    display: flex;
    justify-content: stretch;
    height: 40px;
    padding: ($module-rem / 2) ($module-rem * 1.5);
    max-width: 500px;
    min-width: 60%;
    transition: background-color .2s ease-in-out;

    .icon {
      margin-right: $module-rem * 1.2;
    }
    .mat-form-field {
      width: 100%;
    }
    .mat-form-field-wrapper {
      padding: 0;
      .mat-input-element {
        color: $gray;
        font-size: 12px;
      }
      .mat-form-field-infix {
        padding: 0;
        border: none;
      }
    }

    &:hover,&.focus {
      background-color: $hover-bg;
    }
  }
  &.block {
    .search-input-wrap {
      max-width: unset;
      width: 100%;
    }
  }
}


@media only screen and (max-width: 600px) { 
    .search {
        display: none;
    }
}