@import '../assets/sass/variables';

.App {
  display: block;
  //padding-left: $side-nav-width;
  //padding-top: $top-bar-height;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .side-bar {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
  }
  .top-bar {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 998;
  }
  .main-content {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;

    > main {
      min-height: calc(100% - #{$top-bar-height});
      margin: 0 auto;
      //padding: ($module-rem * 3.6) ($module-rem * 3.6) ($module-rem * 3.2);
      transition: padding 0.3s ease-in-out;
    }
  }
  &.minimized {
    padding-left: $side-nav-width-minimized;
  }
  &.mobile {
    padding: ($module-rem * 4.4) 0 ($module-rem * 4.4);

    .main-content {
      > main {
        padding: ($module-rem * 2.4) ($module-rem * 1.6) ($module-rem * 3.2);
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .App {
    padding: ($module-rem * 4.4) 0 ($module-rem * 4.4);

    .main-content {
      > main {
        padding: ($module-rem * 2.4) ($module-rem * 1.6) ($module-rem * 3.2);
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .App {
    padding-left: $side-nav-width-minimized;
    padding-top: $top-bar-height;
    .main-content {
      > main {
        padding: ($module-rem * 3.6) ($module-rem * 3.6) ($module-rem * 3.2);
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .App {
    padding-left: $side-nav-width;
    padding-top: $top-bar-height;

    .main-content {
      > main {
        padding: ($module-rem * 3.6) ($module-rem * 3.6) ($module-rem * 3.2);
      }
    }
  }
}
