@import '../../../assets/sass/variables';

$_shape: $shape;
$_profile-gray: #4c5862;

$_facebook-color: #3b5998;
$_twitter-color: #00acee;

.pharmacist-details-page {
  .top-bar-mobile {
    align-items: flex-start;
    display: flex;
    height: 44px;

    @media only screen and (max-width: 768px) {
      align-items: center;
      background: #fff;
      box-shadow: 0 1px 0 $divider;
      color: $accent-blue;
      padding: 0 ($module-rem * 1.6);
      left: 0;
      right: 0;
      position: fixed;
      justify-content: space-between;
      top: 0;
      z-index: 99;
    }
    .back-btn {
      align-items: center;
      color: $table-gray;
      cursor: pointer;
      display: flex;
      font-size: 12px;
      line-height: 16px;

      @media only screen and (max-width: 768px) {
        color: $accent-blue;
      }

      .mat-icon {
        color: $accent-blue;
        font-size: 20px;
        height: 20px;
        width: 20px;
      }
      .text {
        color: $accent-blue;
      }
    }
  }
  .contact-info {
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
    .mat-form-field {
      width: 100%;
      min-height: 55.5px;

      .mat-input-element {
        &:disabled {
          color: $table-black !important;
        }
      }
    }
    .header {
      align-items: center;
      border-bottom: 1px solid $divider;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: ($module-rem * 1.6) ($module-rem * 2.4);
      margin: 0 ($module-rem * 2.4) ($module-rem * 3.2);

      .img-wrap {
        align-items: center;
        border-radius: 50%;
        background: rgba(#c2cfe0, 0.5);
        cursor: pointer;
        display: flex;
        justify-content: center;
        height: 104px;
        position: relative;
        margin-bottom: $module-rem * 2.4;
        width: 104px;
        .no-picture {
          position: absolute;

          .no-picture-icon {
            max-width: 18px;
          }
        }
        .edit-btn {
          align-items: center;
          background: #fff;
          border: none;
          border-radius: 50%;
          bottom: 0;
          box-shadow: 0 4px 4px rgba(#000, 0.08);
          display: flex;
          justify-content: center;
          height: 32px;
          outline: none;
          position: absolute;
          right: 0;
          transition: box-shadow 2s ease-in-out;
          width: 32px;

          &.active {
            .mat-icon {
              color: $accent-blue;
            }
          }
          &:hover {
            box-shadow: 0 2px 2px rgba(#000, 0.08);

            .mat-icon {
              transition: color 0.2s ease-in-out;
              color: $accent-blue;
            }
          }
          .mat-icon {
            color: $dark-blue-text;
            font-size: 14px;
            height: 14px;
            width: 14px;
          }
        }
        > img {
          border-radius: 50%;
          height: 100%;
        }
      }
      .name {
        color: $black;
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: $module-rem / 5;
        white-space: nowrap;
      }

      .role {
        color: $_profile-gray;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: $module-rem * 1.2;
        white-space: nowrap;
      }

      .ont-badge {
        display: block;
        line-height: 25px;
        padding: 0 10%;
        border-radius: 5px;
        text-align: center;
        color: white;
        margin-bottom: $module-rem * 2.4;

        &.active {
          background: $green;
        }
        &.archived {
          background: $yellow;
        }
      }

      .status {
        margin-bottom: $module-rem * 2.4;
      }

      .social-icons {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 84px;
        margin-bottom: $module-rem * 1.6;

        .icon {
          cursor: pointer;
          font-size: 21px;
          height: 21px;
          transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
          width: 21px;

          &.facebook {
            height: 22px;
            width: 10px;
          }
          &.twitter {
            height: 22px;
            width: 21px;
          }
          &:hover {
            color: $accent-blue;

            &.facebook {
              color: $_facebook-color;
            }
            &.twitter {
              color: $_twitter-color;
            }
          }
        }
      }
    }
  }
  .tabs {
    box-shadow: 0 6px 18px rgba(#000, 0.06);
    border-radius: $_shape;
    background: #ffffff;
    margin-bottom: $module-rem * 4.4;

    .tab-content {
      padding: ($module-rem * 1.6) ($module-rem * 3.2) ($module-rem * 2.4);

      .text-area {
        width: 100%;

        .mat-form-field-infix {
          border: none !important;
        }
      }
      .actions {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .icons {
          align-items: center;
          display: flex;
          margin: -$module-rem;

          .mat-icon {
            cursor: pointer;
            color: $dark-blue-text;
            font-size: 16px;
            margin: $module-rem;
            height: 12px;
            transition: color 0.2s ease-in-out;
            width: 12px;

            &:hover {
              color: $accent-blue;
            }
          }
        }
      }
    }
  }
  .activity {
    .top-bar {
      margin-bottom: $module-rem * 1.6;
      height: 22px;

      .label {
        color: #6a707e;
      }
    }
  }
  .no-data {
    align-items: center;
    padding: ($module-rem * 9) 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .no-picture-img {
      margin-bottom: $module-rem * 5;
    }
    .no-history {
      color: $black;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
    }
  }
  .delete-btn {
    position: absolute;
    right: 24px;
    top: 24px;
  }
}

.detail-btn-delete {
  display: flex;
  padding: 10% 0;
  .tc-btn {
    margin: 0 auto;
    width: 250px;

    @media only screen and (max-width: 768px) {
      display: block;
      width: 100%;
    }
  }
}
.action-container {
  padding: 3%;
}

.action-btn {
  line-height: 25px;
  padding: 5%;
  border-radius: 5px;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;

  &.archive {
    background-color: rgba(255, 185, 70, 0.1);
    border-bottom: 4px solid $yellow;
  }

  &.active {
    background-color: rgba(46, 212, 122, 0.1);
    border-bottom: 4px solid $green;
  }

  &.delete {
    background-color: rgba(247, 104, 91, 0.1);
    border-bottom: 4px solid #f7685b;
  }
}

.pharmacist-info {
  padding: 7.3% 5%;
  .contact-header {
    color: $black;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: $module-rem / 5;
    white-space: nowrap;
  }
  .info-container {
    margin-left: 10%;
    margin-top: 9%;
    .info {
      margin-top: 5%;
      .label,
      .info-value {
        display: block;
      }
      .label {
        color: $_profile-gray;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: $module-rem * 1.2;
        white-space: nowrap;
      }
    }
  }
}

.edit-form-container {
  .tc-btn {
    display: block;
    width: 100%;
    padding: 15px !important;
    margin: 35px 0 !important;
  }

  .input-container {
    margin: 25px 0 !important;
  }
}
