@import '../../../assets/sass/variables';

.list-page-header {
  display: flex;
  margin-bottom: 35px;
  .filter {
    label {
      margin-right: 5px;
      color: black;
      font-size: 14px;
    }
  }
  .create-btn {
    margin-left: auto;
  }
}
.pharmacy_list_container {
  border: 1px solid #ebeff2;
  margin-top: 35px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow-y: auto;
  max-height: 350px;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f6f8;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  .pharmacies_list {
    display: flex;
    justify-items: center;
    align-items: center;
    border-bottom: 1px solid #ebeff2;
    padding: 15px;
    transition: box-shadow 0.2s ease-in-out;
    &:hover {
      z-index: 10;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    }

    .add_btn {
      border-radius: 10px;
      background-color: $green;
      color: #fff;
      font-weight: bold;
      padding: 10px 20px;
      cursor: pointer;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    }
  }
}
