@import '../../../assets/sass/variables';

$_shape: $shape;

.Page-settings {
  margin-top: 50px;
  display: flex;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
  .desktop-tabs {
    background: #fff;
    border-radius: $_shape;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 128px);
    margin-right: $module-rem * 3;
    min-width: 255px;

    @media only screen and (max-width: 992px) {
      display: none;
    }
    .MuiButtonBase-root.MuiTab-root {
      text-transform: none;
      align-items: flex-start;
      border-bottom: 1px solid $divider;
      border-right: 2px solid transparent;
      cursor: pointer;
      display: flex;
      padding: ($module-rem * 1.6);
      min-height: 68px;
      transition: border-right-color 0.2s ease-in-out;

      .MuiTab-wrapper {
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        justify-content: flex-start;

        .icon {
          margin-right: $module-rem;

          .mat-icon {
            color: $icon-gray;
          }
        }
        .text {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .title {
            text-align: left;
            color: $black;
            font-size: 13px;
            font-weight: 500;
            line-height: 19px;
            margin: 0;
          }
          .desc {
            color: $gray;
            font-size: 10px;
            line-height: 15px;
          }
        }
      }

      &.Mui-selected {
        .MuiTab-wrapper {
          border-right-color: $accent-blue;

          .icon {
            color: $accent-blue;
            transition: color 0.2s ease-in-out;
          }
        }
      }
    }
  }
  .tablet-tabs {
    background: #fff;
    border-radius: $_shape;
    display: none;
    overflow: hidden;
    height: 68px;
    margin-bottom: $module-rem * 3.2;

    @media only screen and (max-width: 992px) {
      display: block;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  .displayed-section {
    flex: 1;
    .MuiBox-root {
      padding: 0 !important;
    }
  }
}
