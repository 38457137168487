@import "../../../../assets/sass/variables";

$_toggle-bg: #c4c4c4;

.side-bar-toggle {
  align-items: center;
  display: flex;
  cursor: pointer;

  .toggle {
    background: $_toggle-bg;
    border-radius: 2px;
    display: block;
    position: relative;
    height: 14px;
    width: 14px;
    min-width: 14px;
    margin-right: $module-rem * 1.5;
    transition: background-color 0.2s ease-in-out;

    .thumb {
      background: #fff;
      border-radius: 1px;
      display: block;
      position: absolute;
      height: 10px;
      left: 0;
      top: 2px;
      transform: translateX(4px);
      width: 2px;
    }
  }
  .label {
    white-space: nowrap;
    font-size: 11px;
    visibility: visible;
  }
  &.active {
    padding: 0 27px;

    .toggle {
      background: $accent-blue;
      margin: 0;
    }
    .label {
      visibility: hidden;
    }
  }
}
