@import '../_variables.scss';

$_shadow: 0px 8px 32px rgba(#000, 0.12);

.mat-dialog-container {
  box-shadow: $_shadow;
  font-family: $main-ff !important;
  padding: ($module-rem * 2.4) ($module-rem * 3.2) ($module-rem * 3.2) !important;

  @media only screen and (max-width: 992px) {
    padding: $module-rem * 2.4 !important;
  }
}
